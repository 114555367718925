.datagrid-footer-container {
  padding: 10px;
}

.datagrid-footer-container .datagrid-btn-export {
  font-size: 12px;
  height: 31px;
}

.datagrid-pagination .MuiPagination-ul .MuiPaginationItem-page {
  /* background-color: var(--clr-primary); */
  /* color: white; */
  font-size: 14px;
  border-radius: 4px;
  padding: 0;
  height: 24px;
  min-width: 20px;
}

.datagrid-pagination .MuiPagination-ul .MuiPaginationItem-page.Mui-selected {
  background-color: var(--clr-primary);
  color: white;
}

.datagrid-footer-container .datagrid-pagination-container {
  display: flex;
  gap: 1rem;
}

.datagrid-pagination-rowselector .rowselector-btn {
  border: 1px solid var(--clr-gray);
  padding: 0;
  height: 29px;
}

.rowselector-menu .MuiMenu-list {
  min-width: 64px;
  text-align: center;
}

.datagrid-pagination-rowselector .rowselector-btn .MuiButton-endIcon {
  color: var(--clr-gray);
  transition: all 100ms linear;
}

.datagrid-pagination-rowselector .rowselector-btn.open .MuiButton-endIcon {
  transform: rotate(0.5turn);
}

.MuiPagination-ul .MuiPaginationItem-root {
  height: 23px;
  min-width: 29px;
}
