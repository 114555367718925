.no-route-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.no-route-container h1 {
  font-size: 128px;
  color: var(--clr-secondary);
}

.no-route-container p {
  font-size: 30px;
  font-weight: 500;
  color: var(--clr-black);
}

.no-route-container img {
  margin-top: 16px;
}

.no-route-container hr {
  border: 2px solid #d6dce3;
  width: 80%;
  margin-top: -8px;
}
