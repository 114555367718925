.Stepper-Form .MuiStep-root {
  position: relative;
  cursor: pointer;
}

.Stepper-Form .MuiStepLabel-root {
  align-items: flex-start;
  padding: 0;
}

.Stepper-Form .MuiStepLabel-iconContainer {
  font-size: 32px;
  z-index: 10;
}

.Stepper-Form .MuiStep-root:not(:last-child):before {
  content: '';
  position: absolute;
  height: 100%;
  border-left: 1px dashed #bbcee2;
  margin-left: 16px;
}

.Stepper-Form .MuiStepLabel-labelContainer {
  margin-top: -4px;
}

.Stepper-Form .step-number {
  font-size: 16px;
  color: var(--clr-primary);
  font-weight: 400;
}

.Stepper-Form .step-info {
  font-size: 18px;
  color: var(--clr-primary);
  font-weight: 600;
}

.Stepper-Form .Form-Step.Next-Step .step-number,
.Stepper-Form .Form-Step.Next-Step .step-info,
.Stepper-Form-horizontal .Form-Step.Next-Step .step-number,
.Stepper-Form-horizontal .Form-Step.Next-Step .step-info {
  color: #6d7d93;
}

.Stepper-Form .Form-Step svg > *,
.Stepper-Form-horizontal .Form-Step svg > * {
  transition: all 300ms ease;
}

.Stepper-Form .Form-Step.Previous-Step,
.Stepper-Form-horizontal .Form-Step.Previous-Step {
  --color1: #d2eee1;
  --color2: #20a867;
}

.Stepper-Form .Form-Step.Current-Step,
.Stepper-Form-horizontal .Form-Step.Current-Step {
  --color1: #002852;
  --color2: #ffffff;
}

.Stepper-Form .Form-Step.Next-Step,
.Stepper-Form-horizontal .Form-Step.Next-Step {
  --color1: #d6e6f6;
  --color2: #ffffff;
}

.add-container-beforService > .step-form-container > .Stepper-Form > .Current-Step {
  --color1: #d6e6f6;
  --color2: #ffffff;
}

.Stepper-Form .Form-Step.Disable-Step {
  --color1: #d6e6f6;
  --color2: #ffffff;
}

.Stepper-Form .MuiStepConnector-root {
  margin-left: 16px;
}

.Stepper-Form .MuiStepConnector-root .MuiStepConnector-line {
  border-color: #bbcee2;
  border-left-style: dashed;
  border-left-width: 1px;
}
