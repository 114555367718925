.list-container {
  /* margin: 1rem 4rem 1rem; */
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.list-title-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
}

.new-ts-list {
  padding: 0.5em;
}

.new-ts-list .list-datagrid-container {
  padding: 0em;
}

.list-title-container .MuiTextField-root {
  margin-left: auto;
}

.list-filter-container {
  background-color: var(--bg-secondary);
  padding: 1rem 1.5rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  align-items: center;
  border-radius: 8px;
}

.list-filter-container .btn-filter {
  margin-left: auto;
  font-size: 12px;
  height: 32px;
  min-width: 96px;
}

.list-filter-container .clear-btn {
  color: #6d7d93;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32.266px;
  text-decoration-line: underline;
}

.list-filter-container .filter-title {
  color: var(--clr-gray);
  line-height: 20px;
  font-size: 14px;
  font-weight: 500;
}

.list-datagrid-container {
  background-color: var(--bg-secondary);
  padding: 1rem 1.5rem;
  border-radius: 8px;
}

.required::after {
  content: '*';
  color: #ef3061;
}

.list-title-container h1 {
  font-size: 26px;
}

/* Holiday list css */
.holiday-list {
  background-color: #fff;
  margin-top: 2rem;
}

.holiday-list .list-title-container {
  padding: 2rem 1.5rem 0;
}

.holiday-list .list-filter-container {
  padding: 0.5rem 1.5rem;
}

@media only screen and (max-width: 1024px) {
  .list-filter-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .list-title-container .MuiTextField-root {
    margin-left: unset;
  }

  .list-filter-container .btn-filter {
    margin-left: unset;
  }

  .list-filter-container .MuiButton-root.MuiButtonBase-root {
    padding: 0;
  }
}
