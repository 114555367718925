.current-balance,
.minimum-balance {
  background-color: var(--bg-blue);
  padding: 2em;
  border-radius: 8px;
  box-shadow: 0px 0px 32.02892303466797px 0px rgba(0, 11, 52, 0.01);
  flex-basis: 300px;
  flex-grow: 1;
}

.workshop-details {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap-reverse;
  gap: 32px;
  justify-content: flex-end;
}

.current-balance .price,
.minimum-balance .price {
  color: #181c32;
  font-size: 22px;
  font-weight: 700;
  margin: 1em 0 .5em;
}

.current-balance .price-text,
.meta-details .secondary-title,
.minimum-balance .price-text {
  color: #6d7d93;
  font-size: 14px;
  margin-bottom: .5rem;
}