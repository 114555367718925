/***** Add Form *****/

.add-booking-container h1 {
  margin-bottom: 2rem;
}

.add-booking-container label {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 600;
}

.step-form-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.add-booking-container .form-btn-container {
  margin-top: 1rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.add-booking-container .form-btn-container button {
  min-width: 120px;
}

.step-form-container > * {
  border-radius: 8px;
}

.step-form-container .form-column-2 .field-container {
}

.step-form-container .Stepper-Form {
  background-color: var(--bg-blue);
  padding: 3rem 1rem;
  flex-basis: 200px;
  flex-grow: 2;
  max-width: 400px;
  height: fit-content;
}

.step-form-container .booking-form-container {
  flex-basis: 500px;
  flex-grow: 5;
}

.step-form-container .booking-form-container .booking-seletion {
  background-color: white;
  padding: 2rem;
  margin-bottom: 1rem;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.step-form-container .booking-form-container .booking-seletion .customer_add,
.step-form-container .booking-form-container .booking-seletion .tractor_details,
.step-form-container .booking-form-container .booking-seletion .service_details,
.step-form-container .booking-form-container .booking-seletion .workshop_details {
  flex-basis: calc(50% - 1rem);
}

.step-form-container .booking-form-container .booking-seletion .customer_add p,
.step-form-container .booking-form-container .booking-seletion .tractor_details p,
.step-form-container .booking-form-container .booking-seletion .service_details p,
.step-form-container .booking-form-container .booking-seletion .workshop_details p {
  /* margin: 0.5rem 0; */
  line-height: 1.25rem;
}

.step-form-container .booking-form-container .booking-seletion .customer_add h3,
.step-form-container .booking-form-container .booking-seletion .tractor_details h3,
.step-form-container .booking-form-container .booking-seletion .service_details h3,
.step-form-container .booking-form-container .booking-seletion .workshop_details h3 {
  margin-bottom: 0.5rem;
}

.step-form-container .booking-form-container .booking-form {
  background-color: white;
  padding: 2rem;
}

.add-booking-container .info-container {
  background-color: var(--bg-blue);
  padding: 1rem 1.5rem;
}

.add-booking-container .info-container h3 {
  font-size: 22px;
  margin-bottom: 16px;
}

.add-booking-container .info-container > div {
  display: flex;
  gap: 16px;
}

.add-booking-container .info-container > div > div > p:nth-child(1) {
  color: var(--clr-gray);
  font-size: 14px;
  margin-bottom: 8px;
}

.add-booking-container .info-container > div > div > p:nth-child(2) {
  color: var(--clr-black);
  font-size: 16px;
}

/* Booking Summary View */

.booking-summary-container {
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 16px;
}

.booking-summary-container > * {
  background-color: white;
  border-radius: 3px;
  padding: 24px;
}

.booking-summary-container > .booking-details {
  order: 1;
  grid-row: 1/3;
}

.booking-summary-container > .customer-details {
  order: 2;
}

.booking-summary-container > .coupon-details {
  order: 3;
  display: flex;
  gap: 16px;
}

.booking-summary-container > .price-details {
  order: 5;
  grid-column: 2/-1;
}

.booking-summary-container > .button-container {
  order: 4;
  background-color: transparent;
  padding: 0;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.booking-summary-container button {
  min-width: 120px;
}

.booking-summary-container h2 {
  font-size: 22px;
  font-weight: 600;
}

.booking-summary-container .grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.grid > p {
  color: var(--clr-gray);
}

.grid > div {
  color: var(--clr-black);
  font-weight: 500;
}

.grid > hr {
  grid-column: 1/ -1;
  width: 100%;
  border: 2px solid #f2f7ff;
  margin: 0px;
}

.coupon-details h3 {
}

.coupon-details p {
  color: var(--clr-gray);
  font-size: 14px;
}

.coupon-details button {
  margin-top: 24px;
}

.coupon-details .view-link {
  font-size: 12px;
  color: red;
}

.coupon-details .view-link > a {
  text-decoration: underline;
}

.coupon-details .view-link span {
  padding-left: 2px;
}

.price-details .grid {
  grid-template-columns: 1fr auto;
}

.price-details .grid {
}
