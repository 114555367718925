.dashboard-heading-container {
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.dashboard-heading-container .date-menu-wrapper {
  margin-left: auto;
}

.dashboard-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.sales-dashboard-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1.5rem;
  margin-top: 2rem;
}

.dashboard-card-head-conatiner {
  display: flex;
  justify-content: space-between;
}

.total-data .dasboard-card-dgt {
  color: var(--clr-primary);
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

.total-data .dasboard-card-title {
  color: var(--clr-gray);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.44px;
}

.total-data .dashboard-card-total-revenue {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

.ts-registration-data-container .dashboard-card-data-conatiner-bottom {
  display: flex;
  justify-content: space-between;
}

.dashboard-container .dashboard-card,
.total-data {
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  box-shadow: 0px 0px 32.02892px 0px rgba(0, 11, 52, 0.01);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 8px;
}

.sales-dashboard-container .dashboard-card,
.total-data {
  background-color: var(--bg-secondary);
  padding: 1.5rem;
  box-shadow: 0px 0px 32.02892px 0px rgba(0, 11, 52, 0.01);
  display: flex;
  gap: 1rem;
  flex-direction: column;
  border-radius: 8px;
}

.dashboard-card .dasboard-comp-icon {
  font-size: 40px;
}

.tbtm .dashboard-card:hover {
  background-color: var(--clr-secondary);
  cursor: pointer;
  box-shadow: 22.66667px 22.66667px 45.33333px -13.6px rgba(0, 0, 0, 0.25);
}

.total-data:hover {
  background-color: var(--clr-secondary);
  cursor: pointer;
  box-shadow: 22.66667px 22.66667px 45.33333px -13.6px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
}

.dashboard-container .total-data:hover .dasboard-card-dgt,
.sales-dashboard-container .total-data:hover .dasboard-card-dgt {
  color: var(--bg-secondary);
}

.dashboard-container .total-data:hover .dasboard-card-title,
.sales-dashboard-container .total-data:hover .dasboard-card-title {
  color: var(--bg-secondary);
}

.dashboard-container .tbtm:hover .dasboard-card-dgt,
.sales-dashboard-container .tbtm:hover .dasboard-card-dgt {
  color: var(--bg-secondary);
}

.dashboard-container .tbtm:hover .dasboard-card-title,
.sales-dashboard-container .tbtm:hover .dasboard-card-title {
  color: var(--bg-secondary);
}

.total-data:hover .hoverable {
  --color2: #fff;
  --border: #ec2726;
  --bg: #ff4e4e;
  --clr-primary: #fff;
  --clr-secondary: #fff;
}

.dashboard-card-data-conatiner-top .dasboard-card-dgt {
  font-size: 35px;
  padding-bottom: 1rem;
}

.dashboard-card-data-conatiner-bottom .dasboard-card-dgt {
  padding-bottom: 1rem;
}

.dashboard-card .dasboard-card-dgt {
  color: var(--clr-primary);
  font-size: 35px;
  font-weight: 600;
  line-height: 32px;
}

.dashboard-card .dasboard-card-title {
  color: var(--clr-gray);
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
  letter-spacing: -0.44px;
}

.dashboard-container .dashboard-data-grid,
.dashboard-service-data-container,
.dashboard-booking-data-grid {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.dashboard-container .dashboard-data-grid {
  grid-column: 1/-1;
}

.dashboard-container .dashboard-service-data-heading-container,
.dashboard-booking-data-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.sales-dashboard-container .dashboard-data-grid,
.dashboard-service-data-container,
.dashboard-booking-data-grid {
  background-color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.sales-dashboard-container .dashboard-data-grid {
  grid-column: 1/-1;
}

.sales-dashboard-container .dashboard-service-data-heading-container,
.dashboard-booking-data-heading-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.dashboard-service-data-heading-container > p {
  font-size: 18px;
  font-weight: 600;
}

.dashboard-booking-data-heading-container > p {
  font-size: 18px;
  font-weight: 600;
}

.card-dash .dasboard-card-dgt {
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  color: var(--clt-black);
}

.card-dash .dasboard-card-title {
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.dashboard-container .card-containers {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0rem 1rem;

  /* flex-wrap: wrap; */
}

.sales-dashboard-container .card-containers {
  /* display: flex; */
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 0rem 1rem;

  /* flex-wrap: wrap; */
}

.dashboard-container .card-containers .service-card,
.booking-card {
  margin-top: 2rem;
  flex-grow: 1;
  /* flex-basis: 150px; */
  border-radius: 4px;
  height: 100px;
  padding: 1.2rem;
  box-shadow: 0px 0px 29.65641px 0px rgba(0, 6, 62, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 1rem;
  /* max-width: 30%; */
}

.sales-dashboard-container .card-containers .service-card,
.booking-card {
  margin-top: 2rem;
  flex-grow: 1;
  /* flex-basis: 150px; */
  border-radius: 4px;
  height: 100px;
  padding: 1.2rem;
  box-shadow: 0px 0px 29.65641px 0px rgba(0, 6, 62, 0.08);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  gap: 1rem;
  /* max-width: 30%; */
}

.booking-card:nth-child(1) {
  background-color: #ebebeb;
}

.booking-card:nth-child(2) {
  background-color: #fffbe7;
}

.booking-card:nth-child(3) {
  background-color: #d8eefe;
}

.booking-card:nth-child(4) {
  background-color: #d2eee1;
}

.booking-card:nth-child(5) {
  background-color: #ec27268f;
}

.dashboard-booking-data-grid .dasboard-card-dgt.card-dgt {
  color: var(--clr-primary);
}

.dashboard-booking-data-grid .dasboard-card-title.card-title:nth-child(1) {
  color: var(--clr-gray);
}

/* For DashBoard DataGrid Table */
.dashboard-data-grid .list-container {
  margin-top: 1rem;
  gap: 0;
}

/*  */
.ts-total-revenue-data-container {
  grid-column: 1/2;
  grid-row: 1/3;
}

.dashboard-container .ts-brands-data-container {
  grid-column: 2/3;
  grid-row: 1/2;
}

.sales-dashboard-container .ts-brands-data-container {
  grid-column: 1/2;
  grid-row: 1/2;
}

.dashboard-container .ts-model-data-container {
  grid-column: 2/3;
  grid-row: 2/3;
}

.sales-dashboard-container .ts-model-data-container {
  grid-column: 1/2;
  grid-row: 2/3;
}

.dashboard-container .ts-orders-data-container {
  grid-column: 3/4;
  grid-row: 1/3;
}

.sales-dashboard-container .ts-orders-data-container {
  grid-column: 2/3;
  grid-row: 1/3;
}

.ts-registration-data-container {
  grid-row: 5/6;
  grid-column: 4/6;
}

.dashboard-service-data-container {
  grid-row: 4/5;
  grid-column: 4/6;
}

.sales-dashboard-container .dashboard-service-data-container {
  grid-row: 1/3;
  grid-column: 3/6;
}

.dashboard-booking-data-grid {
  grid-row: 1/4;
  grid-column: 4/6;
}

.dashboard-container .dashboard-data-grid {
  grid-row: 3/7;
  grid-column: 1/4;
}

.sales-dashboard-container .dashboard-data-grid {
  grid-row: 3/7;
  grid-column: 1/-1;
}

@media only screen and (max-width: 1400px) {
  .dashboard-container .dashboard-data-grid {
    grid-column: 1/4;
    grid-row: 3/7;
  }

  .dashboard-container .dashboard-service-data-container,
  .dashboard-container .dashboard-booking-data-grid {
    grid-column: 4/6;
  }

  .dashboard-container > .dashboard-service-data-container {
    grid-column: 4/6;
  }

  .dashboard-container > .dashboard-booking-data-grid {
    grid-column: 4/-1;
  }

  .sales-dashboard-container .dashboard-data-grid {
    grid-column: 1/-1;
    grid-row: 3/7;
  }

  .sales-dashboard-container .dashboard-service-data-container,
  .sales-dashboard-container .dashboard-booking-data-grid {
    grid-column: 4/6;
  }

  .sales-dashboard-container > .dashboard-service-data-container {
    grid-column: 3/6;
  }

  .sales-dashboard-container > .dashboard-booking-data-grid {
    grid-column: 4/-1;
  }

  /* .dashboard-container .card-containers .service-card,
    .booking-card{
        max-width: 47%;
    } */
}

@media only screen and (max-width: 1200px) {
  .dashboard-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .sales-dashboard-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .total-data .dashboard-card-total-revenue {
    gap: 1rem;
  }

  .ts-total-revenue-data-container {
    grid-column: 1/2;
    grid-row: 1/3;
  }

  .dashboard-container .ts-brands-data-container {
    grid-column: 1/2;
    grid-row: 3/4;
  }

  .sales-dashboard-container .ts-brands-data-container {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .dashboard-container .ts-model-data-container {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .sales-dashboard-container .ts-model-data-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .dashboard-container .ts-orders-data-container {
    grid-column: 2/3;
    grid-row: 1/3;
  }

  .sales-dashboard-container .ts-orders-data-container {
    grid-column: 3/5;
    grid-row: 1/3;
  }

  .ts-registration-data-container {
    grid-column: 3/5;
    grid-row: 1/3;
  }

  .dashboard-container > .dashboard-service-data-container {
    grid-column: 2/5;
    grid-row: 3/5;
  }

  .dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .dashboard-container .dashboard-data-grid {
    grid-column: 1/5;
    grid-row: 6/7;
  }

  .dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(5, 1fr);
  }

  .sales-dashboard-container > .dashboard-service-data-container {
    grid-column: 1/5;
    grid-row: 3/5;
  }

  .sales-dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .sales-dashboard-container .dashboard-data-grid {
    grid-column: 1/5;
    grid-row: 6/7;
  }

  .sales-dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(5, 1fr);
  }

  /* .dashboard-container .card-containers .booking-card.card-dash {
        max-width: 23%;
    } */
}

@media only screen and (max-width: 1024px) {
  /* .dashboard-container {
        grid-template-columns: repeat(3, 1fr);
    }

    .dashboard-container>.dashboard-service-data-container {
        grid-column: 1/3;
        grid-row: 3/4;
    }

    .dashboard-container>.dashboard-booking-data-grid {
        grid-column: 3/5;
        grid-row: 3/4;
    } */
}

@media only screen and (max-width: 900px) {
  /* .dashboard-container {
        grid-template-columns: 1fr 1fr;
    }

    .dashboard-container>.dashboard-service-data-container {
        grid-column: 1/3;
        grid-row: 4/5;
    }

    .dashboard-container>.dashboard-booking-data-grid {
        grid-column: 1/3;
        grid-row: 5/6;
    } */
  .dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(4, 1fr);
  }

  .sales-dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .dashboard-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .sales-dashboard-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .ts-model-data-container {
    grid-column: 2/3;
    grid-row: 3/4;
  }

  /* .dashboard-container {
        grid-template-columns: 1fr;
    } */

  .dashboard-container > .dashboard-service-data-container {
    grid-column: 1/5;
    grid-row: 4/5;
  }

  .dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .dashboard-container > .dashboard-data-grid {
    display: none;
  }

  .sales-dashboard-container > .dashboard-service-data-container {
    grid-column: 1/5;
    grid-row: 3/4;
  }

  .sales-dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/5;
    grid-row: 5/6;
  }

  .sales-dashboard-container > .dashboard-data-grid {
    display: none;
  }

  .ts-registration-data-container {
    grid-row: 1/4;
  }

  .ts-registration-data-container .dashboard-card-data-conatiner-bottom {
    flex-direction: column;
    gap: 1rem;
  }
}

@media only screen and (max-width: 600px) {
  .dashboard-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .sales-dashboard-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .ts-total-revenue-data-container {
    grid-column: 1/3;
    grid-row: 1/2;
  }

  .ts-brands-data-container {
    grid-column: 1/2;
    grid-row: 4/5;
  }

  .ts-model-data-container {
    grid-column: 2/3;
    grid-row: 4/5;
  }

  .ts-orders-data-container {
    grid-column: 1/3;
    grid-row: 2/3;
  }

  .ts-registration-data-container {
    grid-column: 1/3;
    grid-row: 3/4;
  }

  .dashboard-container > .dashboard-service-data-container {
    grid-column: 1/3;
    grid-row: 5/6;
  }

  .dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/3;
    grid-row: 6/7;
  }

  .sales-dashboard-container > .dashboard-service-data-container {
    grid-column: 1/5;
    grid-row: 3/4;
  }

  .sales-dashboard-container > .dashboard-booking-data-grid {
    grid-column: 1/3;
    grid-row: 6/7;
  }

  /* .dashboard-container .card-containers .booking-card.card-dash {
        max-width: 32%;
    }
    .dashboard-container .card-containers .service-card, .booking-card{
        flex-basis: 100px;
    } */
  .dashboard-container .card-containers {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(2, 1fr);
  }

  .dashboard-container > .dashboard-service-data-container .card-containers,
  .dashboard-container > .dashboard-booking-data-grid .card-containers {
    gap: 1rem;
  }

  .sales-dashboard-container .card-containers {
    grid-template-columns: repeat(2, 1fr);
  }

  .sales-dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: repeat(2, 1fr);
  }

  .sales-dashboard-container > .dashboard-service-data-container .card-containers,
  .sales-dashboard-container > .dashboard-booking-data-grid .card-containers {
    gap: 1rem;
  }
}

@media only screen and (max-width: 350px) {
  .dashboard-container .card-containers {
    grid-template-columns: auto;
  }

  .dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: auto;
  }

  .sales-dashboard-container .card-containers {
    grid-template-columns: auto;
  }

  .sales-dashboard-container .dashboard-booking-data-grid .card-containers {
    grid-template-columns: auto;
  }

  .sales-dashboard-container .ts-brands-data-container {
    grid-column: 1/2;
    grid-row: 1/2;
  }

  .sales-dashboard-container .ts-model-data-container {
    grid-column: 1/2;
    grid-row: 2/3;
  }

  .sales-dashboard-container .ts-orders-data-container {
    grid-column: 2/4;
    grid-row: 1/3;
  }
}
