.action-menu-wrapper button.action-menu-btn.MuiButton-root {
  background-color: #f9f9f9;
  font-size: 14px;
  height: 28px;
  padding: 4px 12px 4px 20px;
  color: var(--clr-gray);
  font-weight: 500;
}

.action-menu-wrapper.active button.action-menu-btn.MuiButton-root {
  background-color: #dbedff;
  color: var(--clr-primary);
}

.action-menu .MuiList-root {
  min-width: 100px;
}

.action-menu .MuiList-root .MuiMenuItem-root {
  font-size: 14px;
  color: var(--clr-gray);
}

/* .action-menu .MuiList-root .MuiMenuItem-root:hover {
  background-color: #EC272622;
} */
