.filter-btn-container {
  display: flex;
  gap: 8px;
  align-items: center;
}

/* .filter-selector-container {} */

.filter-selector-container > .filter-selector-btn {
  font-size: 14px;
  font-weight: 500;
  line-height: 0;
  padding: 0 8px;
  height: 24px;
}

.filter-selector-container .MuiButton-endIcon {
  margin-left: 4px;
}

.filter-selector-menu .MuiList-root.MuiMenu-list {
  padding: 0;
}

.filter-item.MuiButtonBase-root.MuiMenuItem-root {
  font-size: 14px;
  padding: 8px 16px;
}

.filter-item.selected.MuiButtonBase-root.MuiMenuItem-root {
  background-color: var(--clr-secondary);
  color: white;
}

.filter-item:not(.selected).MuiButtonBase-root.MuiMenuItem-root:hover {
  background-color: #ec272622;
}

/* Apply To MultiSelect */
.filter-item.MuiButtonBase-root.MuiMenuItem-root .MuiCheckbox-root {
  padding: 4px;
  padding-left: 0px;
  margin-right: 8px;
}

.filter-item.MuiButtonBase-root.MuiMenuItem-root .MuiCheckbox-root.Mui-checked {
  color: var(--clr-secondary);
}

.filter-item.MuiButtonBase-root.MuiMenuItem-root .MuiCheckbox-root .MuiSvgIcon-root {
  height: 0.75em;
  width: 0.75em;
}
