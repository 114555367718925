.login-main {
  display: flex;
  width: 100%;
  height: 100vh;
}

.login-info {
  width: 50%;
  background: var(--clr-primary);
  background-image: url('../../../public/assets/images/tractor-login-bg.svg');
  background-repeat: no-repeat;
  background-size: 50%;
  background-position: 120% 100%;
  color: white;
  font-weight: 500;
  font-size: 4vw;
  padding: 35vh 8%;
}

.login-form-main {
  /* width: 50%; */
  /* padding: 35vh 10%; */
  align-self: center;
  padding-left: 10%;
}

.login-form-main img {
  width: 15vw;
  padding-bottom: 2vw;
}

.login-form-main h2 {
  font-size: 2.5vw;
  padding-bottom: 0.5vw;
}

.login-form-main h5 {
  font-size: 0.8vw;
  padding-bottom: 1vw;
  font-weight: 300;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  justify-content: space-between;
}

.login-form label {
  font-size: 0.8vw;
}

.login-field-phone {
  margin-bottom: 1vw !important;
}

.login-field-phone .MuiOutlinedInput-root {
  padding-left: 0.8vw;
  border-radius: 0.5vw !important;
  border-width: 0.5vw !important;
}

.login-field-phone .MuiInputAdornment-root {
  margin-right: 0.5vw;
}

.login-field-phone .MuiTypography-root {
  font-size: 0.8vw;
}

.login-field-phone .MuiInputBase-input {
  padding: 1vw 0.5vw 1vw 0;
  border: 0.2vw;
  font-size: 0.8vw;
  height: 1vw;
}

.login-field-phone .MuiOutlinedInput-notchedOutline {
  border-width: 0.1vw;
}

.login-btn button {
  width: 100%;
  height: 2vw !important;
  font-size: 0.8vw !important;
}

.otp-form {
  display: flex;
  margin-bottom: 1vw;
  flex-direction: row;
}

.login-field-otp {
  display: inline !important;
  width: 4vw;
  font-size: 1vw;
  text-align: center;
}

.login-field-otp .MuiInputBase-input {
  padding: 1vw 0.5vw 1vw 0.5vw;
  border: 0.2vw;
  font-size: 1vw;
  height: 1vw;
}

.resend-otp {
  margin-top: 1vw;
  font-size: 1vw;
}

.resend-otp-btn {
  color: #ec2726;
  cursor: pointer;
  font-weight: 500;
}

@media only screen and (max-width: 768px) {
  .login-form-main {
    padding: 20vh 10%;
  }

  .login-main {
    flex-direction: column;
  }

  .login-form-main {
    width: 100%;
  }

  .login-info {
    font-size: 6vw;
    width: 100%;
    padding: 15vh 8%;
    background-size: 35%;
    background-position: 100% 100%;
  }

  .login-form-main img {
    width: 35vw;
  }

  .login-field-phone .MuiTypography-root,
  .login-field-phone .MuiInputBase-input {
    font-size: 3.5vw;
  }

  .login-field-phone .MuiOutlinedInput-root {
    padding-left: 2vw;
  }

  .login-field-phone .MuiInputBase-input {
    height: 7vw;
  }

  .login-form-main h2 {
    font-size: 5vw;
  }

  .login-form label {
    font-size: 2.5vw;
  }

  .login-btn button {
    height: 7vw !important;
    font-size: 2.8vw !important;
  }

  .login-form-main h5 {
    font-size: 2.5vw;
    padding-bottom: 2vw;
  }
  .otp-form {
    margin-bottom: 2vw;
  }
  .resend-otp {
    margin-top: 2vw;
    font-size: 2.5vw;
  }
  .login-field-otp {
    width: 12vw;
  }
  .login-field-otp .MuiInputBase-input {
    height: 8vw;
  }
}

@media only screen and (max-width: 600px) {
  .login-info {
    padding: 10vh 8%;
  }
  .login-form-main {
    padding: 15vh 10%;
  }
}
