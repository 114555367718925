.customerViewDescription {
  color: var(--clr-gray);
  line-height: 36px;
}

.customerCountCardContainer {
  display: flex;
  align-content: space-between;
  gap: 20px;
  flex-wrap: wrap;
}

.customerCountCard {
  background-color: var(--bg-blue);
  padding: 18px;
  border-radius: 8px;
}

.customerCountCard>p {
  color: var(--clr-gray);
  font-weight: 500;
}

.customerDetails {
  background-color: white;
  border-radius: 8px;
  padding: 1.5rem;
}

.addreDetailsTabContainer {
  background-color: var(--bg-blue);
  padding: 18px;
  border-radius: 5px;
  /* width: 600px; */
  /* margin-bottom: 20px; */
}

.addreDetailsTabContainer>p {
  line-height: 30px;
  font-weight: 500;
}

.address-details-btn {
  position: absolute;
  top: -1.5rem;
  right: 1rem;
}

.address-details-card-container {
  display: flex;
  position: relative;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 1.5rem;
}

.tractor-details-card-container {
  display: grid;
  position: relative;
  grid-template-columns: repeat(auto-fit, minmax(170px, 250px));
  gap: 1.5rem;
  padding-top: 1.5rem;
}

.tractorDetailsTabContainer {
  background-color: var(--bg-blue);
  padding: 1.5rem;
  border-radius: 5px;
  flex-basis: 700px;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  /* height: 200px; */
}

.tractorDetailsTabContainer.deactiveCard {
  filter: grayscale();
}

.tractorDetailsContainer {
  display: flex;
  gap: 2rem;
  line-height: 25px;
  flex-wrap: wrap;
}

.editContainer {
  display: flex;
  gap: 3rem;
  justify-content: space-between;
  align-items: center;
}

.icon-edit.edit-icon-Button {
  /* display: flex; */
  background-color: #d4dbe287;
  padding: 8px;
  border-radius: 80%;
  font-size: 14px;
  cursor: pointer;
}

.form-container-tractor {
  /* width: min(100vw, 600px); */
  width: min(50vw, 600px);
  display: flex;
  flex-direction: column;
}

.form-container-address {
  /* width: min(100vw, 600px); */
  width: min(50vw, 600px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-container-tractor .column-two {
  display: flex;
  gap: 1rem;
}

.field-container {
  display: flex;
  flex-direction: column;
}

.form-container-tractor .column-two .field-container {
  flex-grow: 1;
  flex-basis: 500px;
}

.popup .MuiDialog-paper {
  padding: 2rem;
}

.popup.edit-profile .MuiDialog-paper {
  max-width: unset;
}

.form-container-tractor-popup {
  /* width: min(100vw, 600px); */
  width: min(50vw, 800px);
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.form-container-tractor-popup .column-two {
  display: flex;
  gap: 1rem;
}

.form-container-tractor-popup .column-two .field-container {
  flex-grow: 1;
  flex-basis: 500px;
}

.form-container-tractor-popup .form-column-2 .field-container>label {
  font-weight: 500;
}