.tab__item__container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  gap: 1rem;
  font-size: 18px;
}

.item__unavailable {
  position: absolute;
  top: 30%;
  left: 50%;
  transform: translate(-34%, -50%);
  font-size: 18px;
  width: 100%;
  font-weight: 500;
}

.item__available {
  position: absolute;
  top: 6%;
  left: 41%;
  transform: translate(-34%, -50%);
  font-size: 18px;
  width: 100%;
  font-weight: 500;
}

.item__available>span {
  background: #F2F7FF;
  padding: 0.1rem 0.8rem;
}

.tab__mrp__total {
  text-decoration: line-through;
  color: #6D7D93;
  margin-right: 1rem;
}