.workshop__booking__container {
  display: flex;
  width: 100%;
  gap: 1rem;
}



.order__history__notavial {
  text-align: center;
  padding: 5rem;
}

.customer__notfound {
  max-width: unset;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background: #ec272614;
}

.customer__found {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: #ec272614;
}

.customer__found__para {
  padding: 12px;
  border: 1px solid #ec2726;
  font-weight: 600;
  color: #ec2726;
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
  display: flex;
  justify-content: center;
  align-self: center;
}

.customer__found__details {
  color: #ec2726;
  font-weight: 600;
}

.notfound__text {
  font-size: 13px;
  font-weight: 600;
  color: #ec2726;
}

/* address found  */
.address__found {
  display: flex;
  gap: 1rem;
  align-items: center;
  padding: 1rem;
  background: #ec272614;
}

.address__found__add {
  font-weight: 600;
  color: #ec2726;
}

/* customer address not avail pop */
.request__otp__header {
  background-color: #f2f7ff;
  padding: 0.5rem;
  padding-left: 1.5rem;
}

.request__otp__container .field-container .MuiFormControl-root {
  background-color: #fdeeee;
}

.request__otp__container .field-container .MuiTextField-root .MuiInputBase-input {
  padding: 1rem;
}

.request__otp__container .field-container {
  padding: 1.5rem;
  width: 60%;
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.otp__timer {
  display: flex;
  justify-content: space-between;
}

.otp__field {
  max-width: 40%;
}

.customer__details__fields {
  padding-bottom: 1rem;
}

.customer__details__para {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
}

.customer__actions {
  display: flex;
  gap: 1rem;
}

/* customer booking cart */
.customer__cart__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
}

.customer__cart__firstsec {
  flex: 1;
  background-color: white;
}

.customer__cart__secondsec {
  background-color: white;
  padding: 1rem;
  display: flex;
  justify-content: end;
}

/* customer order step 2 start here */
.customer-order {
  display: grid;
  gap: 32px;
  grid-template-areas:
    'customer_order_details customer-cart'
    'customer-tractor customer-cart'
    'items-viewtab-container customer-cart';
  grid-template-columns: 2fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
}

.customer-order>* {
  /* min-height: 200px; */
  background-color: white;
  border-radius: 8px;
}

.customer-order .customer_order_details {
  grid-area: customer_order_details;
}

.customer-order .customer-cart {
  grid-area: customer-cart;
  gap: 32px;
  display: flex;
  flex-direction: column;
  background-color: transparent;
  justify-content: space-between;
}

.customer-order .customer-cart>* {
  background-color: white;
  border-radius: 8px;
  min-height: 100px;
}

.customer-order .customer-cart>.cart {
  flex-grow: 1;
}

.customer-order .customer-tractor {
  grid-area: customer-tractor;
}

.customer-order .items-viewtab-container {
  grid-area: items-viewtab-container;
}

.popup.edit-profile .MuiDialog-paper {
  max-width: unset;
  padding: 0px;
  width: 51%;
  overflow-x: hidden;
}

/* customer order step 2 end here */
/* disabled popup*/
.not__verify {
  display: none;
}