.datagrid.MuiDataGrid-root {
  /* DataGrid Container */
  border: none;
  color: var(--clr-black);
  font-weight: 500;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-withBorderColor {
  border-color: #d9d9d940;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-footerContainer.MuiDataGrid-withBorderColor {
  border: none;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-columnSeparator {
  /* Hide Header Seperator */
  display: none;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-columnHeaderTitle {
  color: var(--clr-gray);
  font-weight: 500;
  line-height: 1.2;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-iconButtonContainer button {
  font-size: 14px;
}

/* Disable Grid Cell Outline On Selection of Header Cell or Row Cell */
.datagrid.MuiDataGrid-root .MuiDataGrid-columnHeader:focus,
.datagrid.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within,
.datagrid.MuiDataGrid-root .MuiDataGrid-cell:focus,
.datagrid.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-iconButtonContainer {
  visibility: visible;
}

.datagrid.MuiDataGrid-root .MuiDataGrid-overlayWrapper {
  height: 200px;
}

.datagrid-no-data-found {
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.clickable-row {
  cursor: pointer;
}

.header-align-top.MuiDataGrid-columnHeader .MuiDataGrid-columnHeaderTitleContainer {
  align-items: flex-start;
  padding-top: 8px;
}

.custom-datagrid-header {
  color: var(--clr-gray);
  line-height: 1.2;
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
}

/* Action column override center allignment */
.MuiDataGrid-columnHeader--alignCenter .MuiDataGrid-columnHeaderTitleContainer {
  justify-content: flex-start !important;
}

.MuiDataGrid-row .MuiDataGrid-cell--textCenter {
  justify-content: flex-start !important;
}

.multiline-header .MuiDataGrid-columnHeaderTitle {
  white-space: break-spaces;
}

@media only screen and (max-width: 600px) {
  .list-datagrid-container .MuiDataGrid-footerContainer.datagrid-footer-container {
    flex-wrap: wrap;
    gap: 1rem;
    margin-top: 20px;
  }
}
