.view-page-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-top: 2rem;
}

.view-info-container {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  position: relative;
  padding: 2rem 1rem;
  border-radius: 8px;
}

.info-container {
  max-width: 70%;
}

.view-edit-btn {
  position: absolute;
  top: 2rem;
  right: 1rem;
}

.view-edit-btn > button.MuiButtonBase-root {
  font-size: 12px;
  height: 32px;
  min-width: 96px;
}

.info-image.MuiAvatar-root {
  width: 100px;
  height: 100px;
  box-shadow: 0px 0px 16px #00000020;
  background-color: transparent;
}

.title-container {
  display: flex;
  align-items: center;
  gap: 20px;
}

.description-container {
  color: var(--clr-gray);
  /* max-width: calc(100% - 25%); */
  max-width: 75%;
  overflow-wrap: anywhere;
}

.info-grid {
  margin-left: 116px;
  flex-basis: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  row-gap: 32px;
  column-gap: 16px;
}

.info-grid .info-grid-item {
  display: flex;
  gap: 8px;
}

.info-grid-item .item-title {
  color: var(--clr-gray);
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 4px;
}

.info-grid-item .item-info {
  color: var(--clr-primary);
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
}

.info-grid-item .item-icon {
  font-size: 24px;
}

@media only screen and (max-width: 1024px) {
  .info-grid {
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
}

@media only screen and (max-width: 900px) {
  .info-container {
    max-width: 50%;
  }
  .title-container {
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 600px) {
  .info-container {
    max-width: 100%;
  }

  .view-info-container {
    flex-direction: column;
  }

  .title-container {
    flex-wrap: wrap;
    overflow-wrap: anywhere;
  }

  .info-grid {
    margin-left: 0;
  }
}
