/* .ew-view .title-container {} */

.ew-view .status-action-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 1rem;
  margin-block: 1rem;
}

.ew-view .status-action-container .status {
  padding: 1rem;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  color: var(--clr-primary);
  font-weight: 600;
  background-color: var(--bg-blue);
  margin-right: auto;
}

.ew-view .status-action-container .status.pending {
  background-color: #fffbe7;
}

.ew-view .status-action-container .status.cancelled {
  background-color: #f6e7e7;
}

.ew-view .details-container {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 16px;
}

.ew-view .details-container > * {
  background-color: white;
  border-radius: 3px;
  padding: 24px;
}

.ew-view .details-container > .warranty-details {
  grid-row: 1/5;
  border-right: 1px solid #ebebeb;
}

.ew-view .details-container > .price-details {
  grid-column: 2/-1;
}

.ew-view .details-container button {
  min-width: 120px;
}

.ew-view .details-container h2 {
  font-size: 22px;
  font-weight: 600;
}

.ew-view .details-container .grid {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;
}

.grid > p {
  color: var(--clr-gray);
}

.grid > div {
  color: var(--clr-black);
  font-weight: 500;
}

.grid > hr {
  grid-column: 1/ -1;
  width: 100%;
  border: 2px solid #f2f7ff;
  margin: 0px;
}

.price-details .grid {
  grid-template-columns: 1fr auto;
}

.download-details > div {
  font-weight: 500;
  font-size: 16px;
}

.download-details > div > span {
  font-size: 15px;
  display: inline-flex;
  position: relative;
  top: 2px;
}
