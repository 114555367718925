.add-container {
  padding: 2rem;
  background-color: var(--bg-secondary);
}

.add-container h1 {
  margin-bottom: 40px;
}

.add-container label {
  color: var(--clr-primary);
  font-size: 16px;
  font-weight: 600;
}

.form-wrapper {
  max-width: 1200px;
}

.field-container>label {
  line-height: 1;
  margin-bottom: 16px;
}

.add-container .form-wrapper .form-btn-container {
  margin-top: 1rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.add-container .form-wrapper .form-btn-container>button {
  flex-basis: 136px;
}

.add-container .form-wrapper .form-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 16px;
}

.form-column-2 {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
}

.form-column-2>* {
  flex-basis: 200px;
  max-width: 300px;
  flex-grow: 1;
}

.form-container>.form-textinputs {
  flex-basis: 600px;
  flex-grow: 1;
  max-width: 600px;
}

.form-container>.form-upload-container {
  flex-basis: 200px;
  flex-grow: 1;
  max-width: 300px;
}

.form-container>.form-btn-container {
  flex-shrink: 0;
}

.form-textinputs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.field-container {
  display: flex;
  flex-direction: column;
  /* gap: 16px; */
  /* overflow-x: auto; */
}

.form-error-text {
  /* Mui-Error Default Color */
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 2px;
  height: 14px;
}

.form-discount-text {
  color: #108a4f;
  font-size: 14px;
  margin-top: 4px;
  margin-bottom: 2px;
  height: 14px;
}

.form-info-text {
  color: #6d7d93;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 2px;
  height: 14px;
}

#address__modal>.custom-panel {
  padding: 40px 0px;
}

.form-wrapper .form-container.form-price {
  display: unset;
}

.form-price .price-input-row {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 1rem;
}

.form-price .price-title {
  flex-basis: 148px;
  font-size: 18px;
  font-weight: 600;
}

.form-price .form-column-2 {
  margin-top: 4px;
  flex-basis: 416px;
  flex-grow: 1;
  gap: max(1rem, 10%);
  /* gap: max(1rem, calc(10% + 14.8px)); */
  justify-content: flex-start;
}

.price-display-container {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: max(1rem, calc(10% - 14.8px));
  /* gap: max(1rem, 10%); */
}

.price-display-container .price-box {
  background-color: #f2f7ff;
  padding: 2rem;
  border-radius: 3px;

  /* Accrding to "price-title" and "field-container"
  price-title + field-container: 148px + (200px to 300px) + gap (16px)
  */
  flex-grow: 1;
  flex-basis: 364px;
  max-width: 464px;

  display: grid;
  grid-template-columns: 1fr auto;
  grid-template-rows: auto auto;
}

.price-box .price-heading {
  grid-column: 1 / -1;
  font-size: 22px;
  font-weight: 600;
}

.price-box .price-title {
  font-size: 18px;
  font-weight: 500;
  color: var(--clr-black);
}

.price-box .price-amount {
  font-size: 18px;
  color: var(--clr-black);
}

.price-box .price-total {
  font-size: 30px;
  font-weight: 600;
}

.last-grid-column {
  grid-column: -2 / -1;
}

.price-box .discount {
  margin-top: 4px;
  border-radius: 3px;
  font-size: 14px;
  padding: 4px 6px;
  display: inline-block;
  background-color: #20a867;
  color: white;
}

.price-box hr {
  grid-column: 1 / -1;
  width: 100%;
  border: 2px solid white;
}

.end {
  text-align: end;
}

.icon-delete {
  cursor: pointer;
}

.icon-delete-disabled {
  cursor: not-allowed;
}

.icon-delete:focus {
  outline: none;
}

@media only screen and (max-width: 600px) {
  .price-display-container .price-box {
    padding: 1rem;
  }

  .price-box .price-title {
    align-self: center;
  }

  .price-box .price-total {
    font-size: 25px;
  }
}