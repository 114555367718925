div.total-summary {
  background-color: #f2f7ff;
  padding: 1rem;
  margin: -16px;
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}

div.div_added {
  background-color: #e8eeeeb8;
  padding: 8px;
  margin: 3px -16px;
}

div.total-summary>div {
  display: flex;
  justify-content: space-between;
}

div.total-summary>div>p {
  font-weight: 600;
}