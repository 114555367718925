.servicetab__card__container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(700px, 700px));
  gap: 2rem;
}

.servicetab__card {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 2rem;
  background-color: #F2F7FF;
  padding: 2rem;
  border-radius: 7px;
}

.mrp__total {
  text-decoration: line-through;
  color: #6D7D93;
  font-weight: 600;
  font-size: 1.3rem;
  padding-right: 0.5rem;
  min-width: 100px;
  opacity: 0.7;
}

.offer__total {
  font-weight: 600;
  font-size: 1.3rem;
  min-width: 110px;
  color: #002852;
}

.cardtab__content {
  font-size: 17px;
  font-weight: 500;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  gap: 1rem
}

.cardtab__Service {
  font-size: 17px;
  font-weight: 500;
  display: grid;
  grid-template-columns: 20px 1fr;
  padding-top: 0.6rem;
  padding-bottom: 0.7rem;
  gap: 1rem
}