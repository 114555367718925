/* Apply to All MUI Buttons */
.MuiButton-root.MuiButtonBase-root {
  border-radius: 3px;
  padding: 4px 16px;
  height: 32px;
  text-transform: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 32px;
  box-shadow: none;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined {
  border: 1px solid #ec2726;
  color: #ec2726;
  /* min-width: 90px; */
}

.MuiButton-root.MuiButtonBase-root.MuiButton-outlined.Mui-disabled {
  border: 1px solid #a4a4a4;
  color: #a4a4a4;
  /* min-width: 90px; */
}

.MuiButton-root.MuiButtonBase-root.MuiButton-contained {
  background-color: #ec2726;
  color: #ffffff;
  min-width: 90px;
}

.MuiButton-root.MuiButtonBase-root.MuiButton-contained.Mui-disabled {
  background-color: #a4a4a4;
  color: #ffffff;
}

.MuiButton-startIcon.MuiButton-iconSizeMedium > *:nth-of-type(1) {
  font-size: inherit;
}
