.demo-container {
  width: 100%;
  box-sizing: border-box;
  padding: 16px;
}

.demo-tabel {
  border-collapse: collapse;
  width: 100%;
  border: 1px solid #ddd;
}

.demo-tabel tr {
  text-align: left;
}

.demo-tabel tr:hover {
  background-color: #eee;
}

.demo-tabel td,
.demo-tabel th {
  padding: 8px;
  border: 1px solid #ddd;
}

.demo-tabel td.alignCenter,
.demo-tabel th.alignCenter {
  text-align: center;
}

.copy-component-btn {
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #00000055;
  height: 32px;
  width: 32px;
}
