.table-container-order {
  margin-bottom: 16px;
}

.table-order-service {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0px;
  --border-clr: #d6dce3;
  --border-radius: 4px;
  border: 1px solid var(--border-clr);
  border-radius: var(--border-radius);
}

.table-order-service thead {
  text-align: left;
}

.table-order-service tbody {
  vertical-align: top;
}

.table-order-service thead tr,
.table-order-service tr>* {
  padding: 16px 24px;
  border: 1px solid var(--border-clr);
}

.table-order-service tr:first-child th:first-child {
  border-top-left-radius: var(--border-radius);
}

.table-order-service tr:first-child th:last-child {
  border-top-right-radius: var(--border-radius);
}

.table-order-service tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}

.table-order-service tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}

.order-view-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.order-view-container .title-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.order-view-container .ws-details-container {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.order-view-container .ws-details-container>* {
  background-color: var(--bg-secondary);
  padding: 1.5rem 1.5rem;
}

.order-view-container .ws-details-container .ws-details {
  flex-grow: 3;
  flex-basis: 470px;
  /* background-color: var(--bg-secondary); */
  /* padding: 1.5rem 1.5rem; */
  /* padding: 32px; */
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
}

.order-view-container .ws-details-container .order-date-team {
  flex-grow: 1;
  flex-basis: 320px;
  padding: 2rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
}

.order-view-container .ws-details-container .order-date-team>div {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.order-view-container .ws-details-container .order-date-team>div>div:nth-child(1) {
  color: var(--clr-primary);
  font-size: 18px;
  font-weight: 500;
}

.order-view-container .ws-details-container .order-date-team>div>div:nth-child(2) {
  color: var(--clr-black);
  font-size: 16px;
  font-weight: 500;
}

.ws-details .ws-info {
  flex-basis: 300px;
  flex-grow: 1;
}

.ws-details .ws-info>div {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.order-view-container .order-details {
  background-color: var(--bg-secondary);
  padding: 1.5rem 1.5rem;
  border-radius: 8px;
}

.order-view-container .item-details {
  background-color: var(--bg-secondary);
  padding: 1.5rem 1.5rem;
  border-radius: 8px;
}

.ws-item-list-container {
  flex-basis: 300px;
  flex-grow: 1;
  max-width: 400px;

  background-color: #f2f7ff;
  border-radius: 3px;
  padding: 1rem;
}

.ws-item-list-container .ws-details {
  display: flex;
  gap: 16px;
}

.ws-item-list-container .ws-details .title {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

/* .ws-item-list-container .order-details {} */

.ws-item-list-container .order-details table {
  width: 100%;
}

.ws-item-list-container .order-details table th {
  text-align: start;
  color: var(--clr-primary);
  font-weight: 600;
  padding: 16px 0px 8px;
}

.ws-item-list-container .order-details table td {
  color: var(--clr-primary);
}

@media only screen and (max-width: 1024px) {
  .table-order-service {
    width: unset;
  }
}