.notification-menu-container {
  /* padding: 1rem; */
  width: max(300px, 30vw);
}

.notification-menu-container .heading-container {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  align-items: baseline;
  padding: 1rem;
  padding-bottom: 0.5rem;
}

.notification-menu-container .heading-container > p {
  margin-left: auto;
  font-size: 0.8rem;
  text-decoration: underline;
  letter-spacing: 0.5px;
  cursor: pointer;
}

.notification-menu-container > div {
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow-y: auto;
}

.notification-item-container {
  display: flex;
  gap: 1rem;
  border-bottom: 1px solid var(--bg-primary);
  padding: 0.75rem 1rem 0.25rem;
  cursor: pointer;
  /* width: max(300px, 30vw); */
}

.notification-item-container > div {
  width: 100%;
}

.notification-item-container:hover {
  background-color: #ec272622;
}

.notification-item-container.unread .title {
  color: var(--clr-primary);
}

.notification-item-container .title {
  color: var(--clr-gray);
  font-size: 0.9rem;
  letter-spacing: 0.1px;
  font-weight: 500;
  margin-bottom: 0.25rem;
}

.notification-item-container .time-lapsed {
  color: var(--clr-gray);
  font-size: 0.7rem;
  font-weight: 500;
  text-align: end;
}

.notification-item-container:last-child {
  border-bottom: none;
}

.notification-item-container .action-btn {
  margin-top: 0.5rem;
  display: flex;
  gap: 1rem;
}
