@import './system-design/style.css';
@import './module/style.css';
@import './mui/style.css';
@import './icons.css';
@import './list-layout.css';
@import './view-layout.css';
@import './add-form.css';
@import './react-hook-form.css';

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/gilroy-regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/gilroy-medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/gilroy-semibold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'gilroy';
  src: url('../assets/fonts/gilroy-bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

:root {
  --clr-primary: #002852;
  --clr-secondary: #ec2726;
  --clr-gray: #6d7d93;
  --clr-black: #181c32;
  --clr-green: #20a867;

  --bg-primary: #f5f8fa;
  --bg-secondary: #ffffff;
  --bg-blue: #f2f7ff;
  --bg-white: #ffffff;
}

body {
  margin: 0;
  font-family: 'gilroy', 'Segoe UI', 'Roboto', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body * {
  /* outline: 1px solid #FF000055; */
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
