.appWrapper {
  min-height: 100vh;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto 1fr;
  background-color: #f5f8fa;
}

.appWrapper>header {
  grid-column: 2 / -1;
  grid-row: 1 / 2;
}

.appWrapper>aside {
  grid-column: 1 / 2;
  grid-row: 1 / -1;
  background-color: var(--clr-primary);
}

.appWrapper>main {
  grid-column: 2 / -1;
  grid-row: 2 / -1;
  padding: 2rem 3rem;
  /* max-width: calc(100% - 20px); */
  overflow: auto;
}

.appWrapper>main:has(.overflow__not__required) {
  overflow: visible;
}

@media only screen and (max-width: 768px) {
  .appWrapper {
    grid-template-columns: 80px 1fr;
  }

  .appWrapper>aside {
    grid-row: 1 / 2;
    background-color: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .appWrapper>main {
    grid-column: 1 / -1;
    padding: 2rem 2rem;
  }
}