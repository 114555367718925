.filter-selector-menu.date-range-selector .MuiList-root {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 5px;
}

.filter-datepicker-field {
  width: 148px;
}

.dash {
  width: 6px;
  height: 2px;
  /* field border color */
  background-color: rgba(0, 0, 0, 0.23);
}

/* DatePicker */
.filter-datepicker-field .MuiIconButton-root.Mui-disabled {
  --clr-primary: rgba(0, 0, 0, 0.23);
  --clr-secondary: rgba(0, 0, 0, 0.23);
}
