.table-basic-info-servicekit {
  table-layout: fixed;
  width: 100%;
  border-spacing: 0px;
  --border-clr: #d6dce3;
  --border-radius: 4px;
  border: 1px solid var(--border-clr);
  border-radius: var(--border-radius);
  margin: 16px 0px 16px 0px;
}

.table-basic-info-servicekit thead {
  text-align: left;
}

.table-basic-info-servicekit tbody {
  vertical-align: top;
}

.table-basic-info-servicekit thead tr > * {
  padding: 1rem 1.5rem;
}

.table-basic-info-servicekit tbody tr > * {
  padding: 0.5rem 0rem;
}

.table-basic-info-servicekit tr > * {
  border: 1px solid var(--border-clr);
}

.table-basic-info-servicekit tr:first-child th:first-child {
  border-top-left-radius: var(--border-radius);
}

.table-basic-info-servicekit tr:first-child th:last-child {
  border-top-right-radius: var(--border-radius);
}

.table-basic-info-servicekit tr:last-child td:first-child {
  border-bottom-left-radius: var(--border-radius);
}

.table-basic-info-servicekit tr:last-child td:last-child {
  border-bottom-right-radius: var(--border-radius);
}

.add-step {
  width: fit-content;
  display: inline-flex;
  gap: 8px;
  align-items: center;
  color: var(--clr-primary);
  cursor: pointer;

  margin-bottom: 32px;
}

.add-step .icon > svg {
  display: block;
}

.add-step .text {
  text-decoration: underline;
}

.action-column-cell {
  vertical-align: middle;
  padding-left: 1.5rem;
}

.tabel-label > th,
.tabel-label > td {
  color: var(--clr-primary);
  font-weight: 600;
}

.action-column-cell .border {
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  border: 1px solid #d6dce3;
  border-radius: 3px;
}

.table-basic-info-task {
  table-layout: fixed;
  width: 100%;
  border-spacing: 16px;
  margin: -16px 0px 0px -16px;
}

th.step-column {
  width: 75%;
}

th.action-column {
  width: 25%;
}
