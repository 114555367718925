@media only screen and (max-width: 1280px) {
  .popup.edit-profile.edit-cart-tractor .MuiDialog-paper {
    min-height: 80vh;
  }
}

@media only screen and (min-width: 1300px) {
  .popup.edit-profile.edit-cart-tractor .MuiDialog-paper {
    min-height: 60vh;
  }
}

.form-container-tractor-popup .form-column-2 {
  justify-content: flex-start;
  gap: 2rem;
}