.order-summary {
  background-color: var(--bg-primary);
  margin: -2rem;
}

.order-summary .item-details-list {
  margin-top: 3rem;
}

.order-summary .list-container h1 {
  margin: 1em 0;
}

.order-summary .form-btn-container {
  margin-top: 1rem;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
}

.order-summary .form-btn-container > button {
  flex-basis: 136px;
}
