svg.hoverable {
  --color: #002852;
  --color2: #ec2726;
  --bg: #d4dbe2;
  --border: #e1e8ee;
}

svg.hoverable.hover:hover {
  --color2: #fff;
  --bg: #ff4e4e;
  --border: #ff4e4e00;
}
