.header-wrapper {
  min-height: 80px;
  border-bottom: 2px solid #b4b4b429;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 0rem 3rem;
}

.header-profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

/* .header-user-details {} */

.header-user-details .header-username {
  color: var(--clr-primary);
  font-weight: 700;
  font-size: 18px;
}

.header-user-details .header-userrole {
  color: var(--clr-gray);
  font-size: 14px;
}

.header-icon-container {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 32px;
}

/* Responsive Break-Points - START (Keep In Bottom)*/
@media only screen and (max-width: 768px) {
  .header-wrapper {
    padding: 0rem 2rem 0rem 0.5rem;
  }
}

/* Responsive Break-Points - END ()*/