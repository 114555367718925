.ws-invoice-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.ws-invoice-container .header-container {
  display: flex;
  gap: 1rem;
}

.ws-invoice-container .header-container .btn-container {
  margin-left: auto;
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

.ws-invoice-container>.invoice-info-card {
  background-color: var(--bg-white);
  border-radius: 0.5rem;
  padding: 1rem;

  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
}

.ws-invoice-container>.invoice-info-card>.invoice-info-container>h3 {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-gray);
}

.ws-invoice-container>.invoice-info-card>.invoice-info-container>p {
  font-size: 16px;
  font-weight: 400;
  color: var(--clr-black);
}

.ws-invoice-container>.invoice-info-card>.invoice-info-container>p>b {
  font-weight: 600;
}

.ws-invoice-container>.order-booking-container,
.ws-invoice-container>.order-description-container {
  background-color: var(--bg-white);
  border-radius: 0.5rem;
  padding: 1rem;
}

.ws-invoice-container>.order-booking-container>h2,
.ws-invoice-container>.order-description-container>h2 {
  font-size: 22px;
}

.ws-invoice-container>.order-booking-container table,
.ws-invoice-container>.order-description-container table {
  border-collapse: collapse;
  width: 100%;
}

.ws-invoice-container>.order-booking-container table thead th,
.ws-invoice-container>.order-description-container table thead th {
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-gray);
  text-align: start;
}

.ws-invoice-container>.order-booking-container table tr td,
.ws-invoice-container>.order-description-container table tr td {
  color: var(--clr-black);
  font-size: 16px;
}

.ws-invoice-container>.order-booking-container table tr th,
.ws-invoice-container>.order-booking-container table tr td,
.ws-invoice-container>.order-description-container table tr th,
.ws-invoice-container>.order-description-container table tr:not(.summary-row) td {
  border-bottom: 1px solid #d9d9d929;
  padding: 1rem;
  padding-left: 0rem;
}

.ws-invoice-container>.order-description-container table tr.summary-row td {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.ws-invoice-container>.order-booking-container table tr td:nth-child(3),
.ws-invoice-container>.order-description-container table tr td:nth-child(3),
.ws-invoice-container>.order-description-container table tr td:nth-child(4) {
  font-weight: 500;
}

.ws-invoice-container>.order-description-container table tr.summary-row td:nth-child(3) {
  color: var(--clr-gray);
}

@media only screen and (max-width: 768px) {
  .ws-invoice-container>.invoice-info-card {
    grid-template-columns: 1fr;
  }
}