.tag-container {
  display: inline-flex;
  gap: 8px;
  line-height: 20px;
  padding: 5px 7px;
  align-items: center;
  border-radius: 3px;
  font-weight: 500;
}

.tag-green {
  color: #20a867;
  background-color: #d2eee1;
  width: 98px;
}

.tag-red {
  color: #ec2726;
  background-color: #f6e7e7;
  width: 98px;
}

.tag-info {
  color: #6d7d93;
  background-color: #f2f7ff;
  padding: 5px 16px;
}

.tag-completed {
  color: #20a867;
  background-color: #d2eee1;
  padding: 5px 16px;
}

.tag-ongoing {
  color: #287dbb;
  background-color: #d8eefe;
  padding: 5px 16px;
}

.tag-pending {
  color: #6f6f6f;
  background-color: #ebebeb;
  padding: 5px 16px;
}

.tag-upcoming {
  color: #e8bf30;
  background-color: #fef8d8;
  padding: 5px 16px;
}

.tag-cancelled {
  color: #ec2726;
  background-color: #f6e7e7;
  padding: 5px 16px;
}

.tag-started {
  color: #f97700;
  background-color: #ffd3aa;
  padding: 5px 16px;
}

.tag-total {
  color: #9657ea;
  background-color: #f7f1ff;
  padding: 5px 16px;
}

.tag-processing {
  color: #155c5e;
  background-color: #c7e8ed;
  padding: 5px 16px;
}

.tag-partial {
  color: #6b30ab;
  background-color: #dee0fa;
  padding: 5px 16px;
}

.tag-sku {
  display: inline-block;
  background-color: var(--bg-blue);
  padding: 0.5rem;
}

.tag-sku span {
  font-weight: 700;
}
