.payment__pop__container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 2rem
}

.payment__pop__heading {
  background: #F2F7FF
}

.payment__pop__heading>h4 {
  padding: 10px 2rem
}

.payment_via {
  display: flex;
}