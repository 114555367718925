/* <== Sidebar ==> */
#sidebar {
  --SidebarCloseW: 80px;
  --SidebarOpenW: 240px;
}

#sidebar .MuiDrawer-paper {
  background-color: var(--clr-primary);
  color: white;
  overflow-x: hidden;
}

#sidebar .sidebar-icon {
  display: inline-flex;
}

/* SideBar Logo */
#sidebar .sidebar-logo-header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  min-height: 80px;
  font-size: 40px;
}

.sidebar-logo-header .logo-container {
  display: flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  width: 48px;
  transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

.sidebar-logo-header .logo-container > * {
  flex-shrink: 0;
}

.sidebar-logo-header.open .logo-container {
  width: 177px;
}

/* Direct Link / No nested Links */
#sidebar .sidebar-main-link {
  background-color: inherit;
  color: inherit;
  display: flex;
  align-items: center;
  text-decoration: none;
  gap: 16px;
  height: 48px;
}

#sidebar .sidebar-main-link.active {
  font-weight: 600;
}

#sidebar .sidebar-main-link.active:not(.sidebar-nested-link) {
  background-color: #002245;
}

.MuiAccordionSummary-root:has(~ .MuiCollapse-root a.active) {
  font-weight: 600;
  background-color: #002245;
}

#sidebar .sidebar-main-link.sidebar-nested-link {
  font-size: 14px;
  padding-left: calc(var(--SidebarCloseW) / 2 + 24px);
  height: 32px;
}

.sidebar-nested-link::before {
  content: '';
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: white;
}

#sidebar .sidebar-main-link:hover,
#sidebar .sidebar-accordion .MuiAccordionSummary-root:hover {
  background-color: #002245;
}

.sidebar-accordion .MuiAccordionDetails-root {
  padding: 0;
}

/* Nested Link */
#sidebar .sidebar-accordion {
  background-color: inherit;
  color: inherit;
  box-shadow: none;
  margin: 0;
}

#sidebar .sidebar-accordion .MuiAccordion-root.Mui-expanded,
#sidebar .sidebar-accordion .MuiAccordionSummary-root.Mui-expanded,
#sidebar .sidebar-accordion .MuiAccordionSummary-root {
  /* padding: 0; */
  height: 48px;
  min-height: 48px;
}

#sidebar .sidebar-accordion .MuiAccordionSummary-content {
  display: flex;
  align-items: center;
  gap: 16px;
  margin: 0;
}

#sidebar .sidebar-accordion .MuiAccordionSummary-expandIconWrapper {
  color: inherit;
  transition: all 300ms;
}

#sidebar .MuiAccordionSummary-root.open .MuiAccordionSummary-expandIconWrapper {
  width: 12px;
}

#sidebar .MuiAccordionSummary-root.close .MuiAccordionSummary-expandIconWrapper {
  width: 0px;
}

#sidebar .sidebar-main-link,
#sidebar .sidebar-accordion .MuiAccordionSummary-root {
  padding-left: calc(var(--SidebarCloseW) / 2 - 8px);
}

#sidebar .sidebar-title {
  width: 0px;
  transition: all 300ms;
}

#sidebar .sidebar-title.visible {
  opacity: 1;
  /* display: block; */
}

#sidebar .sidebar-title.hidden {
  opacity: 0;
  /* display: none; */
}

/* Version Info */
#sidebar .versionText {
  margin-top: auto;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 0.8rem;
  transition: all 300ms;
}

#sidebar .versionText.visible {
  opacity: 1;
}

#sidebar .versionText.hidden {
  opacity: 0;
}

#sidebar .MuiPaper-root {
  transition: all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
}

/* For Responsive Sidebar */
#sidebar.close .MuiPaper-root {
  transition:
    all 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
    background-color 200ms linear 225ms;
}

@media only screen and (max-width: 768px) {
  /* For Responsive Sidebar */
  #sidebar.close .MuiPaper-root {
    height: 80px;
    overflow: hidden;
    background-color: transparent;
    border-right: none;
  }

  #sidebar.close .logo-container {
    transition:
      width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms,
      color 200ms linear 225ms;
  }

  #sidebar.close .logo-container {
    color: var(--clr-primary);
  }
  #sidebar .MuiPaper-root.MuiDrawer-paper {
    position: absolute;
  }
}
