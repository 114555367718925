.MuiTabs-root .MuiTabs-flexContainer {
  gap: 4px;
}

.MuiTabs-root .MuiTab-root {
  padding-left: 4px;
  padding-right: 32px;
  text-transform: capitalize;
  font-size: 18px;
  font-weight: 600;
  color: var(--clr-primary);
  box-shadow: inset 0px -3px 0px 0px #d6dce3;
}

.MuiTabs-root .MuiTab-root.Mui-selected {
  color: var(--clr-secondary);
}

.MuiTabs-root .MuiTabs-indicator {
  height: 3px;
  background-color: var(--clr-secondary);
}
